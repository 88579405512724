import Cookies from 'js-cookie'

const UserKey = 'Wx-H5-User'

export function getUser() {
  if (typeof Cookies.get(UserKey) === 'string') {
    return JSON.parse(Cookies.get(UserKey))
  }
  return ''
}

export function setUser(user) {
  Cookies.set(UserKey, user)
}

export function removeUser() {
  Cookies.remove(UserKey)
}