import api from '@/utils/api'

export function getPopularityRanks(params) {
  return api.get('activity/v1/popularityRanks', { params:params })
}

export function popularity(params) {
  return api.get('activity/v1/popularity', { params:params })
}

export function vote(params) {
  return api.post('activity/v1/popularity/vote', params)
}