<template>
  <div class="warpbg">
    <div class="user_header">
      <div v-if="!isLogin" class="user_login">您好，请<span @click="showLoginDialog=true">【登录】</span></div>
      <div v-if="isLogin" class="user_logout" >{{ serverName }} {{ roleName }} <span @click="handleLogout">【退出】</span></div>
      
    </div>
    <div class="frame_top_vocation">
      <div class="box_top_vocation">
        <div class="item_top_vocation" v-if="zhanshiList.length > 0 && zhanshiList[0].ballot > 0">
          <img class="cell_top_vocation_avatar" :src="zhanshiList[0].user.avatar | filterAvatar">
          <div class="cell_top_vocation_server cell_top_vocation_text">{{ zhanshiList[0]['server'] }}</div>
          <div class="cell_top_vocation_role cell_top_vocation_text">{{ zhanshiList[0]['role_name'] }}</div>
          <div class="cell_top_vocation_comment cell_top_vocation_text">{{ zhanshiList[0]['comment'] | filterComment }}</div>
        </div>
        <div class="item_top_vocation" v-if="fashiList.length > 0  && fashiList[0].ballot > 0">
          <img class="cell_top_vocation_avatar" :src="fashiList[0].user.avatar | filterAvatar">
          <div class="cell_top_vocation_server cell_top_vocation_text">{{ fashiList[0]['server'] }}</div>
          <div class="cell_top_vocation_role cell_top_vocation_text">{{ fashiList[0]['role_name'] }}</div>
          <div class="cell_top_vocation_comment cell_top_vocation_text">{{ fashiList[0]['comment'] | filterComment }}</div>
        </div>
        <div class="item_top_vocation item_last_top_vocation" v-if="daoshiList.length > 0  && daoshiList[0].ballot > 0">
          <img class="cell_top_vocation_avatar" :src="daoshiList[0].user.avatar | filterAvatar">
          <div class="cell_top_vocation_server cell_top_vocation_text">{{ daoshiList[0]['server'] }}</div>
          <div class="cell_top_vocation_role cell_top_vocation_text">{{ daoshiList[0]['role_name'] }}</div>
          <div class="cell_top_vocation_comment cell_top_vocation_text">{{ daoshiList[0]['comment'] | filterComment }}</div>
        </div>
      </div>
    </div>

    <!-- 投票候选角色 -->
    <div class="frame_all_candidates">
      <!-- 战士 -->
      <div class="box_zhanshi_candidates">
        <div class="title_zhanshi_candidates"></div>
        <!-- 战士列表 -->
        <div class="item_candidate" :class="{ 'item_last_candidate': index === 2 }" v-for="(item, index) in zhanshiList.slice(0, 3)" :key="index">
          <div class="cell_candidate">
            <!-- 头像 -->
            <div class="cell_avatar_border">
              <img class="cell_avatar" :src="item.user.avatar | filterAvatar" alt="">
            </div>
            <!-- 角色 -->
            <div class="cell_role">
              <div class="cell_role_name">{{ item.role_name }}</div>
              <div class="cell_server">{{ item.server }}</div>
            </div>
            <div class="cell_ballot">
              <div class="cell_ballot_unit">票</div>
              <div class="cell_ballot_count">{{ item.ballot }}</div>
            </div>
            <div class="btn_vote" @click="handleVote(item.id)"></div>
            
          </div>
          <div v-if="index <= 1" class="cell_line"></div>
        </div>
         <!-- 查看更多 -->
        <div class="btn_candidate_more" @click="handleShowCandidatesMore('zhan')"></div>
      </div>

      <!-- 法师 -->
      <div class="box_fashi_candidates">
        <div class="title_fashi_candidates"></div>
        <div class="item_candidate" :class="{ 'item_last_candidate': index === 2 }" v-for="(item, index) in fashiList.slice(0, 3)" :key="index">
          <div class="cell_candidate">
            <!-- 头像 -->
            <div class="cell_avatar_border">
              <img class="cell_avatar" :src="item.user.avatar | filterAvatar" alt="">
            </div>
            <!-- 角色 -->
            <div class="cell_role">
              <div class="cell_role_name">{{ item.role_name }}</div>
              <div class="cell_server">{{ item.server }}</div>
            </div>
            <div class="cell_ballot">
              <div class="cell_ballot_unit">票</div>
              <div class="cell_ballot_count">{{ item.ballot }}</div>
            </div>
            <div class="btn_vote" @click="handleVote(item.id)"></div>
            
          </div>
          <div v-if="index <= 1" class="cell_line"></div>
        </div>
        <div class="btn_candidate_more" @click="handleShowCandidatesMore('fa')"></div>
      </div>

      <!-- 道士 -->
      <div class="box_daoshi_candidates">
        <div class="title_daoshi_candidates"></div>
        <div class="item_candidate" :class="{ 'item_last_candidate': index === 2 }" v-for="(item, index) in daoshiList.slice(0, 3)" :key="index">
          <div class="cell_candidate">
            <!-- 头像 -->
            <div class="cell_avatar_border">
              <img class="cell_avatar" :src="item.user.avatar | filterAvatar" alt="">
            </div>
            <!-- 角色 -->
            <div class="cell_role">
              <div class="cell_role_name">{{ item.role_name }}</div>
              <div class="cell_server">{{ item.server }}</div>
            </div>
            <div class="cell_ballot">
              <div class="cell_ballot_unit">票</div>
              <div class="cell_ballot_count">{{ item.ballot }}</div>
            </div>
            <div class="btn_vote" @click="handleVote(item.id)"></div>
            
          </div>
          <div v-if="index <= 1" class="cell_line"></div>
        </div>
        <div class="btn_candidate_more" @click="handleShowCandidatesMore('dao')"></div>
      </div>

      <!-- 更多候选角色 -->
      <van-popup class="candidates_more_popup" v-model="showCandidatesMorePopup" get-container="frame_all_candidates">
        <div class="bg_candidate_list">
          <div class="box_candidate_list">
            <div class="box_item_candidate" :class="{ 'box_right_item_candidate': (index+1)%3===0 }" v-for="(item, index) in candidatesMoreList" :key="index">
              <div class="avatar_border">
                <img class="more_cell_avatar" :src="item.user.avatar | filterAvatar" alt="">
              </div>
              <div class="server">{{ item.server}}</div>
              <div class="role_name">{{ item.role_name }}</div>
              <div class="ballot"><span>{{ item.ballot }} </span>票</div>
              <div class="more_btn_vote" @click="handleVote(item.id)"></div>
            </div>
          </div>
        </div>
        <div class="btn_more_vote_confirm" @click="showCandidatesMorePopup=false"></div>
      </van-popup>
    </div>

    <!-- 投票成功奖励 -->
    <van-popup class="boxVoteSuccessPopup" v-model="showVoteSuccessPopup" get-container="candidates_more_popup">
      <div class="box_cdkey">
        <div class="cell_cdkey_text">{{ cdkey}}</div>
        <div class="btn_cdkey_copy" :data-clipboard-text="cdkey" @click="handleCopyCdkey"></div>
      </div>
      <div class="btn_vote_success_confirm" @click="showVoteSuccessPopup=false"></div>
    </van-popup>
    <van-popup class="boxVotedSuccessPopup" v-model="showVotedSuccessPopup" get-container="candidates_more_popup">
      <div class="box_cdkey">
        <div class="cell_cdkey_text">{{ cdkey}}</div>
        <div class="btn_cdkey_copy" :data-clipboard-text="cdkey" @click="handleCopyCdkey"></div>
      </div>
      <div class="btn_vote_success_confirm" @click="showVotedSuccessPopup=false"></div>
    </van-popup>
    
    <!-- 登录 -->
    <van-dialog class="login_dialog" v-model="showLoginDialog" title="玩家登录" confirm-button-text="登录" theme="round-button" 
      confirm-button-color="linear-gradient(to right, #ffa800, #ff3600)" :before-close="handleLoginDialogBeforeClose">
      <div class="van-icon van-icon-close van-popup__close-icon van-popup__close-icon--top-right" @click="handleLoginDialogClose"></div>

      <van-form ref="loginForm">
        <van-field v-model="username" label="账号" placeholder="填写账号" :rules="[{ required: true }]" />
        <van-field v-model="password" type="password" label="密码" placeholder="填写密码" :rules="[{ required: true }]"  />

      </van-form>
    </van-dialog>
    <!-- 选择区服 -->
    <van-dialog class="server_role_dialog" confirm-button-color="linear-gradient(to right, #ffa800, #ff3600)" theme="round-button"
      v-model="showServerRoleDialog" confirm-button-text="确认" :close-on-click-overlay="true" :before-close="handleServerRoleDialogBeforeClose">
      <van-form >

        <van-field
          readonly
          clickable
          name="picker"
          label="游戏区服"
          placeholder="点击选择区服"
          :value="serverName"
          @click="showServerPicker = true"
          :rules="[{ required: true }]"
        />
        <van-popup v-model="showServerPicker" position="bottom" get-container="body">
          <van-picker
            show-toolbar
            :columns="serverColumns"
            @confirm="onServerConfirm"
            @cancel="showServerPicker = false"
            :loading="serverListLoading"
          />
        </van-popup>

        <van-field
          readonly
          clickable
          name="picker"
          label="角色名"
          placeholder="点击选择角色"
          :value="roleName"
          @click="showRolePicker = true"
          :rules="[{ required: true }]"
        />

        <van-popup v-model="showRolePicker" position="bottom" get-container="body">
          <van-picker
            show-toolbar
            :columns="roleColumns"
            @confirm="onRoleConfirm"
            @cancel="showRolePicker = false"
            :loading="roleListLoading"
          />
        </van-popup>
      </van-form>
    </van-dialog>

    <div class="frame_desc">
      <div class="box_desc_title">活动说明:</div>
      <div v-html="desc" class="box_desc_text"></div>
    </div>
  </div>
</template>

<script>
import { popularity, vote } from '@/api/popularity'
import { Base64 } from 'js-base64'
import { parseQueryString } from '@/utils'
import { Popup } from 'vant'
import { webLogin, webLogout } from "@/api/login"
import { serverList, roleList } from "@/api/user"
import { checkAuth, setUsername, getUsername, removeToken } from "@/utils/auth"
import { getUser, setUser, removeUser } from "@/utils/user"
import Clipboard from 'clipboard'

export default {
  components: {
    vanPopup: Popup
  },
  filters: {
    filterAvatar: function(val) {
      let avatar = val
      if (!avatar) {
        avatar = require('@/assets/activity/popularity/avatar.jpg')
      }
      return avatar
    },
    filterComment: function (value) {
      let data = value
      if (!data) {
        data = '是兄弟就来投我'
      }
      return data
    }
  },
  data() {
    return {
      parentGameId: 'c010676a2b86464cad391a09e74373b6',
      isLogin: false,
      numberId: undefined,
      zhanshiList: [],
      fashiList: [],
      daoshiList: [],
      desc: undefined,
      showCandidatesMorePopup: false,
      showVoteSuccessPopup: false,
      showVotedSuccessPopup: false,
      cdkey: undefined,
      // 登录
      showLoginDialog: false,
      username: undefined,
      password: undefined,
      // 区服角色框
      showServerRoleDialog: false,
      // 区服
      showServerPicker: false,
      serverListLoading: true,
      serverColumns: [],
      serverList: undefined,
      serverName: undefined,
      // 角色
      showRolePicker:false,
      roleListLoading: true,
      roleColumns: [],
      roleList: undefined,
      roleName: undefined,
      roleId: undefined,
      // 
      candidatesMoreList: [],
    }
  },
  created() {
    this.parseQuery()
    this.initView()
    this.getAllList()
    this.initUser()
  },
  methods: {
    initView () {
      let scale = 1.0 / window.devicePixelRatio;
      let text = `<meta name="viewport" content="width=device-width, initial-scale=${scale}, maximum-scale=${scale}, minimum-scale=${scale}, user-scalable=no">`;
      document.write(text);
      document.documentElement.style.fontSize = window.innerWidth / 7.5 + "px";
    },
    parseQuery() {
      const queryString = Base64.decode(this.$route.query.p)
      const queryParams = parseQueryString(queryString)
      this.numberId = queryParams.number
      console.log(this.numberId)
    },
    getAllList() {
      popularity({ number_id: this.numberId }).then(response => {
        this.zhanshiList = response.candidates['战士']
        this.fashiList = response.candidates['法师']
        this.daoshiList = response.candidates['道士']
        this.desc = '<p>' + response.activity.desc.replace(/\n/g, '</p><p>') + '</p>'
      })
    },
    handleShowCandidatesMore(type) {
      if (type === 'zhan') {
        this.candidatesMoreList = this.zhanshiList
      } else if (type === 'fa') {
        this.candidatesMoreList = this.fashiList
      } else {
        this.candidatesMoreList = this.daoshiList
      }
      this.showCandidatesMorePopup=true
    },
    // 投票
    handleVote(id) {
      if (this.checkLogin()) {
        vote({ id: id, role_id: this.roleId }).then(response => {
          if (response.first === 0) {
            this.showVotedSuccessPopup = true
          } else {
            this.showVoteSuccessPopup = true
          }
          this.cdkey = response.userProp.cdkey
          if (response.candidates && response.candidates['战士']) {
            this.zhanshiList = response.candidates['战士']
            this.candidatesMoreList = response.candidates['战士']
          } else if (response.candidates && response.candidates['法师']) {
            this.fashiList = response.candidates['法师']
            this.candidatesMoreList = response.candidates['法师']
          } else if (response.candidates && response.candidates['道士']) {
            this.daoshiList = response.candidates['道士']
            this.candidatesMoreList = response.candidates['道士']
          }
        })
      } 
    },
    initUser() {
      // 登录框用户名显示上次的用户名
      this.username = getUsername()
      // 登录状态
      this.isLogin = checkAuth()
      if (this.isLogin) {
        this.account = getUsername()
        console.log(this.account)
        if (!getUser()) {
          this.reqServerList()
        } else {
          const redpacketUser = getUser()
          this.serverName = redpacketUser.serverName
          this.roleId = redpacketUser.roleId
          this.roleName = redpacketUser.roleName
        }
      }
    },
    checkLogin() {
      let login = false
      if (!this.isLogin) {
        this.showLoginDialog = true
      } else if (!this.roleId) {
        this.showServerRoleDialog = true
      } else {
        login = true
      }
      return login
    },
    handleLoginDialogBeforeClose(action, done) {
      if (action === 'confirm') {
        // 验证表单
        this.$refs['loginForm'].validate().then(() => {
          // 调用登录方法
          this.loginSubmit(done)
        }).catch(() => {
          done(false)
        })
      } else {
        done()
      }
    },
    handleLoginDialogClose() {
      this.showLoginDialog = false
    },
    // 登录
    loginSubmit (done) {
      webLogin({ username: this.username, password: this.password }).then(response => {
        console.log(response)
        setUsername(response.account)
        // 登录成功
        this.isLogin = true
        done()
        this.showLoginDialog = false
        // 显示区服选择框
        this.showServerRoleDialog = true
        // 加载区服列表
        this.reqServerList()
      }).catch(() => {
        done(false)
      })
    },
    // 退出
    handleLogout () {
      webLogout().then(response => {
        console.log(response)
      })
      this.isLogin = false
      removeToken()
      removeUser()
      this.roleName = undefined
      this.serverName = undefined
      this.roleId = undefined
      // this.$router.go(0)
      location.reload()
    },
    // 请求区服列表
    reqServerList() {
      this.serverColumns = []
      serverList({ game_id: this.parentGameId }).then(response => {
          this.serverListLoading = false
          this.serverList = response
          response.forEach(element => {
            this.serverColumns.push(element.server)
          })
        })
    },
    // 确认选择区服
    onServerConfirm(value) {
      this.serverName = value
      // 关闭区服选择器
      this.showServerPicker = false
      // 加载区服下角色
      this.reqRoleList()
    },
    // 请求角色列表
    reqRoleList() {
      this.roleColumns = []
      roleList({ game_id: this.parentGameId, server: this.serverName }).then(response => {
        this.roleListLoading = false
        this.roleList = response
        response.forEach(element => {
          this.roleColumns.push(element.role_name)
        })
      })
    },
    // 确认选择角色
    onRoleConfirm(value, index) {
      this.roleName = value // 角色名
      this.roleId = this.roleList[index]['id'] // 角色ID
      console.log('roleId', this.roleId)
      this.showRolePicker = false
    },
    // 区服角色确定
    handleServerRoleDialogBeforeClose(action, done) {
      if (action === 'confirm') {
        
        if (!this.serverName || !this.roleId) {
          this.$toast.fail('选择区服角色')
          done(false)
        } else {
          // localStorage.redpacket_user = JSON.stringify({ serverName: this.serverName, roleId: this.roleId,  roleName: this.roleName})
          setUser({ serverName: this.serverName, roleId: this.roleId,  roleName: this.roleName})
          done()
          // this.$router.go(0)
          location.reload()
        }
      } else {
        done()
      }
      
    },
    // 复制用户已获得奖励列表CDKEY
    handleCopyCdkey() {
      let clipboard = new Clipboard('.btn_cdkey_copy')
      clipboard.on('success', e => {
        this.$toast.success('复制成功')
        e.clearSelection()
        clipboard.destroy() // 释放内存
      })
      clipboard.on('error', e => {
        // 不支持复制
        this.$toast.fail('该浏览器不支持自动复制')
        e.clearSelection()
        clipboard.destroy()
      })
    }
  }
}
</script>

<style>
html,
body {
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
}
img[src=''], img:not([src]) {
  opacity:0
}
.warpbg {
  margin: 0px;
  padding: 0px;
  height: 37.50rem;
  width: 100%;
  background: url('../../assets/activity/popularity/bg.jpg') top center no-repeat;
  background-size: contain;
  position: relative;
}
.user_header {
  position:absolute;
  right: 0.45rem;
  top: 0.22rem;
  font-size: 0.2rem;
  color: white;
  line-height: 0.2rem;
}
.frame_top_vocation {
  width: 100%;
  height: 4.75rem;
  background: url('../../assets/activity/popularity/top_vocation_bg.png') top center no-repeat;
  background-size: contain;
  position: absolute;
  margin-top: 5.86rem;
}
.box_top_vocation {
  margin-top: 1.65rem;
  width: 6.72rem;
  height: 2.36rem;
  margin-left: 0.4rem;
  /* background: red; */
}
.item_top_vocation {
  float: left;
  width: 1.56rem;
  height: 100%;
  margin-right: 1.02rem;
  /* background: white; */
}
/* .item_second_top_vocation {
  margin-right: 0.98rem;
} */
.item_last_top_vocation {
  margin-right: 0;
}
.cell_top_vocation_avatar {
  height: 0.76rem;
  width: 0.76rem;
  border-radius: 50%;
  margin-top: 0.08rem;
  margin-left: 0.4rem;
  float: left;
}
.cell_top_vocation_text {
  width: 1.7rem;
  color: white;
  font-size: 0.2rem;
  text-align: center;
  -webkit-transform: translate(-4%, 0) scaleX(0.9) scaleY(0.9);
  transform: translate(-4%, 0) scaleX(0.9) scaleY(0.9);
  white-space: nowrap;
}
.cell_top_vocation_server {
  font-weight: bold;
  margin-top: 0.09rem;
  float: left;
}
.cell_top_vocation_role {
  font-weight: bold;
  float: left;
}
.cell_top_vocation_comment {
  white-space: normal;
  margin-top: 0.2rem;
  float: left;
}
.frame_all_candidates {
  height: 17.49rem;
  width: 100%;
  margin-top: 10.78rem;
  position:absolute;
}
.box_zhanshi_candidates, .box_fashi_candidates, .box_daoshi_candidates {
  height: 5.83rem;
  width: 100%;
}
.title_zhanshi_candidates {
  height: 0.68rem;
  background: url('../../assets/activity/popularity/title_zhanshi_bg.png') top center no-repeat;
  background-size: contain;
}
.item_candidate {
  height: 1.25rem;
  width: 100%;
  /* background: red; */
  margin-bottom: 0.21rem;
  font-family:"SimHei";
}
.item_last_candidate {
  height: 1.02rem;
  margin-bottom: 0.19rem;
}
.cell_candidate {
  height: 1.02rem;
  width: 100%;
  margin-bottom: 0.21rem;
}
.cell_avatar_border {
  float: left;
  width: 1.02rem;
  height: 1.02rem;
  margin-left: 0.4rem;
  background: url('../../assets/activity/popularity/avatar_border.png') top center no-repeat;
  background-size: contain;
}
.cell_avatar {
  width: 0.86rem;
  height: 0.86rem;
  border-radius: 50%;
  margin-left: 0.08rem;
  margin-top: 0.08rem;
  display: block;
}
.cell_role {
  float: left;
  width: 2.52rem;
  margin-left: 0.28rem;
  margin-top: 0.12rem;
}
.cell_role .cell_role_name {
  width: 100%;
  color: white;
  font-size: 0.3rem;
  /* font-weight: bold; */
}
.cell_role .cell_server {
  width: 100%;
  color: white;
  font-size: 0.2rem;
}
.cell_ballot {
  float: left;
  width: 1.37rem;
  height: 100%;
}
.cell_ballot_count {
  float: right;
  height: 100%;
  line-height: 1.02rem;
  font-size: 0.55rem;
  color: #fed100;
  /* font-weight: bold; */
}
.cell_ballot_unit {
  float: right;
  height: 100%;
  line-height: 1.02rem;
  font-size: 0.25rem;
  margin-left: 0.13rem;
  color: white;
}
.btn_vote {
  float: left;
  width: 1.27rem;
  height: 0.51rem;
  background: url('../../assets/activity/popularity/btn_vote.png') top center no-repeat;
  background-size: contain;
  margin-left: 0.24rem;
  margin-top: 0.26rem;
}
.cell_line {
  height: 0.02rem;
  width: 4.86rem;
  background: url('../../assets/activity/popularity/line.png') top center no-repeat;
  background-size: contain;
  margin-left: 1.60rem;
}
.btn_candidate_more {
  /* width: 1.33rem; */
  width: 100%;
  height: 0.4rem;
  background: url('../../assets/activity/popularity/btn_candidate_more.png') top center no-repeat;
  background-size: contain;
  text-align: center;
}

.title_fashi_candidates {
  height: 0.68rem;
  background: url('../../assets/activity/popularity/title_fashi_bg.png') top center no-repeat;
  background-size: contain;
}
.title_daoshi_candidates {
  height: 0.68rem;
  background: url('../../assets/activity/popularity/title_daoshi_bg.png') top center no-repeat;
  background-size: contain;
}

.candidates_more_popup {
  border-radius: 3%;
  border: 0.03rem solid #fed100;
  background: #220000;
  height: 7.45rem;
  width: 6.95rem;
}
.bg_candidate_list {
  background: #140000;
  margin-left: 0.24rem;
  margin-top: 0.43rem;
  width:6.41rem ;
  height:5.82rem;
  /* position: relative; */
  overflow: scroll;
}
.box_candidate_list {
  margin-top: 0.24rem;
  margin-left: 0.50rem;
  width: 5.41rem;
  height: auto;
  /* position: absolute; */
  
}
.box_item_candidate {
  width: 1.49rem;
  float: left;
  margin-bottom: 0.25rem;
  margin-right: 0.47rem;
}
.box_right_item_candidate {
  margin-right: 0;
}
.box_item_candidate .avatar_border {
  width: 0.96rem;
  height: 0.97rem;
  margin-left: 0.27rem;
  background: url('../../assets/activity/popularity/more_list_avatar_bg.png') top center no-repeat;
  background-size: contain;
}
.more_cell_avatar {
  height: 0.91rem;
  width: 0.91rem;
  margin-left: 0.03rem;
  margin-top: 0.03rem;
  border-radius: 5%;
}
.box_item_candidate .server, .box_item_candidate .role_name, .box_item_candidate .ballot {
  font-size: 0.2rem;
  color: white;
  text-align: center;
  /* font-family:"SimHei"; */
}
.box_item_candidate .ballot span {
  font-size: 0.3rem;
  color: #fed100;
}
.box_item_candidate .more_btn_vote {
  width: 1.49rem;
  height: 0.59rem;
  background: url('../../assets/activity/popularity/more_btn_vote.png') top center no-repeat;
  background-size: contain;
  margin-top: 0.08rem;
}
.btn_more_vote_confirm {
  margin-top: 0.24rem;
  width: 100%;
  height: 0.63rem;
  background: url('../../assets/activity/popularity/btn_more_vote_confirm.png') top center no-repeat;
  background-size: contain;
  text-align: center;
}

/* 投票成功弹出层 */
.boxVoteSuccessPopup {
  width: 4.87rem;
  height: 2.80rem;
  background: url('../../assets/activity/popularity/vote_success_bg.png') top center no-repeat;
  background-size: contain;
}
.boxVotedSuccessPopup {
  width: 4.87rem;
  height: 2.80rem;
  background: url('../../assets/activity/popularity/voted_success_bg.png') top center no-repeat;
  background-size: contain;
}
.box_cdkey {
  margin-left: 0.66rem;
  margin-top: 0.87rem;
  height: 0.34rem;
  width: 3.65rem;
}
.cell_cdkey_text {
  float: left;
  background: white;
  width: 2.58rem;
  height: 100%;
  font-size: 0.2rem;
  line-height: 0.34rem;
  text-align: center;
  font-weight: bold;
}
.btn_cdkey_copy {
  float: left;
  width: 0.9rem;
  height: 100%;
  margin-left: 0.17rem;
}
.btn_vote_success_confirm {
  margin-left: 1.53rem;
  margin-top: 0.17rem;
  width: 1.81rem;
  height: 0.49rem;
}

.frame_desc {
  position:absolute;
  margin-top: 28.5rem;
  margin-left: 0.2rem;
  font-size: 0.2rem;
  color: rgb(249, 209, 87);
}
.box_desc_text p {
  margin: 0.2rem auto;
}
</style>